import React from 'react'
import PropTypes from 'prop-types'

import LinkAddIcon from '../../images/features/linkadd.svg'
import CloudProcessIcon from '../../images/features/cloudprocess.svg'
import DownloadIcon from '../../images/features/download.svg'

const HowToDownload = ({ headingAs = '2' }) => {
  const CustomTag = `h${headingAs}`
  return (
    <section className="statistics bg-white">
      <div className="row">
        <div className="col-sm-12">
          <CustomTag className="text-center">
            How to download from cboxera.com ?
          </CustomTag>
          <p>3 simple steps for generating your premium links for free.</p>
        </div>
      </div>

      <div
        className={`row text-center ${
          CustomTag !== 'h3' ? 'spacing-40' : 'spacing-70'
        }`}
      >
        <div className="col-sm-4">
          <div>
            <img width={90} height={90} src={LinkAddIcon} alt="link add" />
            <h4>Input your link</h4>
            <p>
              Login into your account. <br /> Paste your link in downloader
              page.
            </p>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="wow fadeInUp" data-wow-delay="0.2s">
            <img
              width={90}
              height={90}
              src={CloudProcessIcon}
              alt="cloud processing"
            />
            <h4>Generate Premium Link</h4>
            <p>
              Click on <strong>Download Links</strong> button to start leeching
              your link
            </p>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="wow fadeInUp" data-wow-delay="0.3s">
            <img
              width={90}
              height={90}
              src={DownloadIcon}
              alt="download link"
            />
            <h4>Download your premium link</h4>
            <p>
              In less than 2 seconds, your link will be generated and you simply
              click and download.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

HowToDownload.propTypes = {
  headingAs: PropTypes.string.isRequired,
}

export default HowToDownload
