import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Button } from 'reactstrap'
import { navigate, Link } from 'gatsby'

import { REGISTER, HOST_STATUS, DOWNLOADER } from '../data/constants'

import HowToDownload from '../components/Home/HowToDownload'
import { isUserLoggedIn } from '../selectors/login'

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ''

const FilehostTemplate = ({ pageContext }) => {
  const [hostName] = pageContext.name.split('.')
  const hostWithExtension = pageContext.name
  const hostWithExtensionCaps = capitalize(hostWithExtension)
  const isLoggedIn = useSelector(isUserLoggedIn)

  function handleDownloadClick() {
    navigate(isLoggedIn ? DOWNLOADER : REGISTER)
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`${hostWithExtensionCaps} | Free Premium Link Generator | Cboxera.com`}
        link={[
          {
            rel: 'canonical',
            href: `${process.env.SITE_URL}/host/${hostName}-generator/`,
          },
        ]}
        meta={[
          {
            name: 'keywords',
            content: `${hostName} premium link generator, ${hostName} debrid, ${hostName} debrideur, ${hostName} debrid service, ${hostName} high-speed download, ${pageContext.name} premium link generator, ${hostName} premium, ${pageContext.name} link generator, ${hostName} premium link downloader, ${hostName} premium link creator, ${hostWithExtension} unlimited download`,
          },
          {
            name: 'description',
            content: `Are you tired of paying to download files from ${hostWithExtensionCaps}? Use Cboxera.com to download the files for free, with no limits and advertisements.`,
          },
        ]}
      />
      <div className="hostsection">
        <h1 className="lead text-center pt-4">
          <i className="fa fa-download text-danger pr-2"></i>
          Download from {capitalize(hostName)} at full speed with no limits and
          Ads with Cboxera.
        </h1>
        <div className="text-muted text-center fnt-10 mt-2">
          <i
            title={hostWithExtension}
            className={`hosts_${
              hostWithExtension && hostWithExtension.replace(/\./g, '_')
            } pr-4`}
          ></i>
          {pageContext.name}
        </div>
        <div className="container">
          <h2 className="mt-4">
            Are you tired of waiting hours for your files to download? Are you
            tired of dealing with download limits and slow speeds? Look no
            further than our premium generator for{' '}
            {capitalize(hostWithExtension)}
          </h2>
        </div>
        <div className="container">
          <dl>
            <dt className="question">What is {hostWithExtensionCaps} ?</dt>
            <dd>
              <p>
                {hostWithExtensionCaps} is a popular file hosting service that
                allows users to upload and download files. However, like many
                file hosting services, free users are often faced with long wait
                times, slow download speeds, and download limits. With our
                premium generator for {capitalize(hostName)}, you can bypass
                these limitations and enjoy lightning-fast download speeds and
                unlimited downloads.
              </p>
            </dd>
            <dt className="question">How does it work?</dt>
            <dd>
              <p>
                Our premium generator for {capitalize(hostName)} works by
                generating a unique, premium link for your download. This link
                bypasses the limitations imposed on free users, allowing you to
                download your files at high speeds and without any restrictions.
                Simply enter the link to the file you wish to download and our
                generator will create a premium link for you to use.
              </p>
            </dd>
            <dt className="question">
              Are you looking to download files from another filehost?
            </dt>
            <dd>
              <p>
                <Link to={HOST_STATUS} className="text-danger">
                  <strong>Click here</strong>
                </Link>{' '}
                to see the other 50+ filehosting sites we support.
              </p>
            </dd>
            <dt className="question">Is it legal?</dt>
            <dd>
              <p>
                Using a premium link generator is not illegal, as long as it is
                used for legitimate downloads. However, it is important to note
                that it is against the terms of service for many file hosting
                sites, including {capitalize(hostName)}. We recommend that you
                use the premium link for personal use and not for distribution
                or commercial use.
              </p>
            </dd>
            <dt className="question">Are there any risks?</dt>
            <dd>
              <p>
                As with any online service, there is always a risk of fraud or
                scams. That's why we recommend using a reputable premium link
                generator like ours, which is safe and secure.
              </p>
            </dd>
            <dt className="question">Can I use it for free?</dt>
            <dd>
              <p>
                Our premium generator for {capitalize(hostName)} is available
                for a low monthly fee. However, we also offer a limited free
                version that allows you to generate a limited number of premium
                links per day.
              </p>
            </dd>
            <dt className="question">
              Is there a limit on the number of downloads?
            </dt>
            <dd>
              <p>
                No, with our premium generator, there is no limit on the number
                of downloads. You can download as many files as you need at high
                speeds.
              </p>
            </dd>

            <dt className="question">Will my download speed be faster?</dt>
            <dd>
              <p>
                Yes, with our premium generator, your download speeds will be
                significantly faster than without it.{' '}
              </p>
            </dd>
            <dt className="question">Are there any alternatives?</dt>
            <dd>
              <p>
                There are other premium link generators available, but we
                believe that our generator for {capitalize(hostName)} is the
                best option in terms of speed, reliability, and security.
              </p>
            </dd>
          </dl>
          <h2>
            Get started today and enjoy lightning-fast downloads with our
            premium generator for {hostWithExtensionCaps}.
          </h2>
          <HowToDownload headingAs="3" />
          <div className="container text-center mt-2">
            <Button size="lg" color="danger" onClick={handleDownloadClick}>
              Download from {hostWithExtensionCaps}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

FilehostTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default FilehostTemplate
